import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'DriveLogin',
        component: () => import('../views/driveLogin'),
        meta: {
            title: "司机登录"
        }
    },
    {
        path: '/driveList',
        name: 'DriveList',
        component: () => import('../views/driveList'),
        meta: {
            title: "订单列表"
        }
    },
    {
        path: '/driveOrderInfo/:orderNo?',
        name: 'DriveOrderInfo',
        component: () => import('../views/driveOrderInfo'),
        meta: {
            title: "订单详情",
        }
    },
    {
        path: '/drivePayResult',
        name: 'DrivePayResult',
        component: () => import('../views/drivePayResult'),
        meta: {
            title: "支付结果"
        }
    },
    {
        path: '/payResult',
        name: 'PayResult',
        component: () => import('../views/payResult'),
        meta: {
            title: "支付结果"
        }
    },
    {
        path: '/:shortNo?',
        name: 'Home',
        component: () => import('../views/home'),
        meta: {
            title: "订单详情",
        }
    },
]


const router = new VueRouter({
    mode: 'history',
    // 如果vue.config.js中publicPath打开,这里也同时打开,两边必须同时使用
    // base: process.env.NODE_ENV === "production" ? "/app" : "",
    base: "",
    routes
})

// 后置路由
router.afterEach((to, from) => {
    // 赋值title
    document.title = to.meta.title;
    // 回到顶部
    window.scrollTo(0, 0);
})

// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
